



































import {
  BookResponse,
  Book,
  BookPic,
  Map,
  // eslint-disable-next-line import/extensions
} from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'

export default {
  name: 'BookBasic',
  props: {
    bookId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedItem: -1,
      book: {} as Book,
      items: [] as Array<BookPic>,
    }
  },
  beforeMount() {
    this.fetchBookPics()
      .then(() => {
        this.$emit('loading', false)
      })
      .catch(() => {
        this.$emit('loading', false)
      })
  },
  methods: {
    safeImage(item: Map): string {
      return (item.thumbnail_url) ? item.thumbnail_url : item.image_url
    },
    /**
     * Call books api
     */
    fetchBookPics() {
      return new Promise((resolve, reject) => {
        this.loading = true
        fetch(Urls.url('book', { bookid: this.bookId }, { pics: 'y' }), {
          method: 'GET',
          headers: { Authorization: localStorage.tk },
        }).then((response: Response) => response.json())
          .then((book: BookResponse) => {
            this.loading = false
            if (book.status !== 'Success') {
              reject(book.message)
            } else {
              this.book = book.data || {}
              this.items = book.data.pics || []
              resolve(true)
            }
          })
          .catch((err: string) => {
            Errors.logError(err)
            reject()
          })
      })
    },
  },
}
