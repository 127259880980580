













































import Display from '@/utilities/Display'
import InsideContainer from '@/components/Containers/InsideContainer.vue'
import BookBasic from '@/components/Photobooks/BookBasic.vue'
import Loading from '@/components/Modals/Loading.vue'

export default {
  name: 'Photobook',
  components: {
    InsideContainer,
    BookBasic,
    Loading,
  },
  data() {
    return {
      bookId: '',
      childLoaded: true,
    }
  },
  computed: {
    mobile(): boolean { return Display.mobile(this.$vuetify) },
  },
  beforeMount() {
    this.bookId = this.$route.params.bookid
  },
  methods: {
    childLoading(loading) {
      this.childLoaded = loading
    },
  },
}
